const filterOperatorChoices = [
  { label: "Greater Than", value: "$gt" },
  { label: "Greater Than and Equals to", value: "$gte" },
  { label: "Equals to", value: "$eq" },
  { label: "Less Than", value: "$lt" },
  { label: "Less Than and Equals to", value: "$lte" },
];

export const sourcingFormFields = [
  {
    nested: "group",
    groupSize: 3,
    section: false,
    subfields: [
      {
        id: "keySkills",
        name: "keySkills",
        label: "Skills",
        validated: false,
        type: "text",
        errormsg: "keySkills is required",
        placeholder: `Search with multiple Skills (Example : React JS, Angular)`,
        // helpText: `Search with multiple Skills (Example : React JS, Angular)`,
      },
      {
        id: "workExperience",
        name: "workExperience",
        label: "Work Experience",
        validated: false,
        type: "number",
        errormsg: "Work Experience is required",
        placeholder: `Search with candidate work Experience`,

        // helpText: `Search with candidate work Experience `,
      },

      {
        id: "location",
        name: "location",
        label: "Location",
        validated: false,
        type: "text",
        errormsg: "Location is required",
        placeholder: `Search with candidate Location`,

        // helpText: `Search with candidate Location `,
      },
      {
        id: "preferWorkMode",
        name: "preferWorkMode",
        label: "Work Mode",
        type: "select",
        validated: false,
        errormsg: "Prefer Work Mode is required",
        placeholder: "Search with candidate Work Mode",
        options: [
          { value: "In office", label: "Office" },
          { value: "Remote", label: "Remote" },
          { value: "Hybrid", label: "Hybrid" },
          { value: "Flexible", label: "Flexible" },
        ],
      },
      {
        id: "noticePeriod",
        name: "noticePeriod",
        label: "Notice Period (In days)",
        type: "select",
        validated: false,
        errormsg: "Notice Period is required",
        placeholder: "Select your notice period",
        options: [
          { value: "Currently serving", label: "Currently serving" },
          { value: "Immediate joiner", label: "Immediate joiner" },
          { value: "15", label: "15" },
          { value: "30", label: "30" },
          { value: "60", label: "60" },
          { value: "90", label: "90" },
          { value: "90+", label: "90+" },
        ],
      },
      {
        id: "currentCTC",
        name: "currentCTC",
        label: "Annual Salary",
        nested: "object",
        groupSize: 2,
        section: false,
        subfields: [
          {
            id: "operators",
            name: "operators",
            // label: "",
            type: "select",
            placeholder: "Select an operator",
            options: filterOperatorChoices,
          },
          {
            id: "number",
            name: "number",
            // label: "Value",
            placeholder: "Enter Annual Salary",
            type: "number",
            min: 0,
          },
        ],
      },
    ],
  },
];

export const sourcingInitialValues = {
  workExperience: "",
  location: "",
  keySkills: "",
  preferWorkMode: "",
  currentCTC: "",
  noticePeriod: "",
};
