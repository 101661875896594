import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  BlockStack,
  Box,
  Button,
  Frame,
  InlineStack,
  Loading,
  Modal,
  Page,
  Tabs,
  Text,
  Tooltip,
} from "@shopify/polaris";
import Papa from "papaparse";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { setLocalStorageFilter } from "@/Utils/AppUtils.js";
import {
  bulkSendAssessmentFormFields,
  bulkSendAssessmentInitialValues,
  candidateDetailsInitialValues,
  fetchCandidate,
  fetchCandidateInitialValue,
  getCandidateDetailFields,
  getImportCandidateDetailFields,
  sendAssessmentFields,
  sendAssessmentInitialValues,
} from "../../../Assets/Mocks/Candidate.mock";
import { TotalQuestionsFields, TotalQuestionsInitialValues } from "../../../Assets/Mocks/ToalQuestion.mock";
import { AccessPermission } from "../../../Components/Common/AccessPermission";
import StarJob from "../../../Components/Common/StarJob";
import { UserContext } from "../../../Context/UserContext";
import { removeSessionStorageItem } from "../../../Utils/Index.js";
import Plan from "../../Plan/Plan.jsx";
import { Notifications } from "../Notifications/Notifications.jsx";
import { AssessmentReminder } from "../Settings/AssessmentReminder";
import { Stage } from "../Stage/Stage.jsx";
import { CandidateDetails } from "./CandidateDetails.jsx";
import { ImportBulkResume } from "./ImportBulkResume.jsx";

export const JobDetail = () => {
  const { userProfileData } = useContext(UserContext);
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const [isImportCandidateModelOpen, setIsImportCandidateModelOpen] = useState(false);
  const [candidateFormValues, setCandidateFormValues] = useState(candidateDetailsInitialValues);
  const [actionType, setActionType] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [totalQuestions, setTotalQuestions] = useState(TotalQuestionsInitialValues.totalQuestions);
  const [customQuestion, setcustomQuestion] = useState(TotalQuestionsInitialValues.customQuestion);
  const [isAssessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [isPremiumModelOpen, setIsPremiumModelOpen] = useState(false);
  const [isSendingAssessment, setIsSendingAssessment] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [file, setFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [jobData, setJobData] = useState();
  const formRef = useRef();
  const customQuestionsformRef = useRef();
  const [fileInputRef] = useState(useRef());
  const childRef = useRef();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [parentSelectedTab, setParentSelectedTab] = useState(urlParams.get("tabindex") || 0);
  const key = "candidateList";
  const [options, setOptions] = useState(null);
  const [stepCount, setStepCount] = useState(1);
  const [isUploadCandidate, setIsUploadCandidate] = useState(null);
  const [candidateTotalUpload, setCandidateTotalUpload] = useState(0);
  const [candidateSuccessUpload, setCandidateSuccessUpload] = useState(0);
  const [candidateFailUpload, SetCandidateFailUpload] = useState(0);
  const [resumeCount, setResumeCount] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bulkAction, setBulkAction] = useState();
  const [sendAssessmentModel, setSendAssessmentModel] = useState(false);
  const [NextRound, setNextRound] = useState(false);
  const [isLastStage, setIsLastStage] = useState(false);
  const [NextSendAssessment, setNextSendAssessment] = useState(false);
  const [bulkoptions, setBulkOptions] = useState(null);
  const [assessmentSend, setAssessmentSend] = useState(false);
  const [assessmentSendModel, setAssessmentSendModel] = useState(false);
  const [bulkAssessmnet, setBulkAssessment] = useState();
  const [sendAssessment, setsendAssessment] = useState(false);
  const [candiateData, setcandiateData] = useState();
  const [isSendAssessment, setIsSendAssessment] = useState(false);
  const [notificationsNotRead, setNotificationsNotRead] = useState(0);
  const [addCandidate, setaddCandidate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFetchCandidateModelOpen, setIsFetchCandidateModelOpen] = useState(false);
  const [fatchsearchValue, setFatchSearchValue] = useState();
  const [fetchData, setFatchData] = useState(false);
  const [fetchDataNumber, setFatchDataNumber] = useState(false);

  const pricingConfig = {
    hideHeader: true,
    plans: ["Free", "Premium"],
  };

  const tabs = [
    {
      id: "candidates",
      content: "Candidates",
      accessibilityLabel: "Candidates",
      panelID: "candidates",
    },
    {
      id: "stages",
      content: "Interview Rounds",
      accessibilityLabel: "Interview Rounds",
      panelID: "stages",
    },
    {
      id: "reminder",
      content: "Reminder",
      accessibilityLabel: "Reminder",
      panelID: "reminder",
    },
    {
      id: "notifications",
      content: "Notifications",
      accessibilityLabel: "Notifications",
      panelID: "notifications",
    },
  ];

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelected(selectedTabIndex);
    if (selectedTabIndex === 0) {
      getJobData();
    }
  }, []);
  const handleBulkDeleteClose = useCallback(() => setShowConfirmation(!showConfirmation), [showConfirmation]);
  const handleBulkSendClose = useCallback(
    () => setSendAssessmentModel(!sendAssessmentModel),
    [sendAssessmentModel]
  );

  const handleBulkSendAssessmentClose = useCallback(
    () => setAssessmentSendModel(!assessmentSendModel),
    [assessmentSendModel]
  );

  useEffect(() => {
    getJobData();
  }, [fatchsearchValue, fetchData]);

  useEffect(() => {
    if (sendAssessment) {
      setIsSendAssessment(true);
    }
  }, [sendAssessment, fetchData]);

  const handleSendAssessmentCanceled = useCallback(() => {
    setIsSendAssessment(false);
    setIsFetchCandidateModelOpen(false);
    setsendAssessment(false);
  }, []);

  const handleSenAssessmentConfirmed = useCallback(async () => {
    if (formRef.current && sendAssessment) {
      formRef.current.handleSubmit();
    }
  }, [candiateData, sendAssessment]);

  const handleFatchCndidate = useCallback(async () => {
    formRef.current.handleSubmit();
  }, [candiateData]);

  const saveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleBulkSendAssessment = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction]);

  const handleBulkSendAssessmentMassage = useCallback(() => {
    setAssessmentSend(false);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction, assessmentSend]);

  useEffect(() => {}, [parentSelectedTab, notificationsNotRead]);

  const getJobData = useCallback(async () => {
    localStorage.setItem("playbackSpeed", 1);
    const stagesData = await fetch.get(`/stages/${id}`);
    const stageTitles = stagesData?.data?.rows?.map((item) => ({
      value: item._id,
      label: item.stageDetails.stageTitle,
    }));
    setBulkOptions(stageTitles);
    const candidateTab = document.querySelector(".Polaris-Tabs__TabContainer #candidates");
    if (stagesData?.data?.rows?.length > 0) {
    } else {
      handleTabChange(1);
      document.querySelector("li #candidates")?.classList?.toggle("hidden");
    }

    const mappedTabs = stagesData?.data?.rows?.map((stage, index) => ({
      id: `${stage?.stageDetails?.stageTitle}-${index}`,
      content: stage?.stageDetails?.stageTitle,
      searchQuery: {
        key: "lastStage._id",
        value: stage?._id,
        tabQuery: true,
        type: "marketQuery",
        secondQuery: [{ reject: false }],
      },
      hideFilter: ["reject"],
    }));
    const stageTabs = [{ id: "all", content: "All", hideFilter: [] }];
    if (mappedTabs && mappedTabs.length > 0) {
      stageTabs.push(...mappedTabs);
      stageTabs.push(
        {
          id: "reject",
          content: "Reject",
          searchQuery: { key: "reject", value: true, tabQuery: true },
          hideFilter: ["reject"],
        },
        {
          id: "Shortlist",
          content: "Shortlist",
          searchQuery: { key: "isShortList", value: true, tabQuery: true },
          hideFilter: ["reject"],
        }
      );
    }
    const res = await fetch.get(`/job/${id}`);
    setJobData(res.data);
    const notifications = await fetch.post(`/getNotifications?page=${1}&pageSize=${10}`, res?.data);
    setNotificationsNotRead(notifications?.data?.unreadCount);
  }, [id, setJobData, selected, notificationsNotRead]);

  const handleSubmit = useCallback(
    async (value) => {
      if (candiateData?._id && sendAssessment) {
        setIsSendingAssessment(true);
        await fetch.post("/stage/sendAssessment", {
          jobId: candiateData?.jobId,
          candidateId: candiateData?._id,
          stageId: candiateData.currentStage?._id,
          sendAssessmentType: value,
        });
        setsendAssessment(false);
        setIsSendingAssessment(false);
        setIsSendAssessment(false);
        childRef?.current?.fetchData();
        showToast("Assessment sent successfully");
      } else if (filterValues?.selectedResources) {
        if (
          (value?.sendAssessment?.includes("whatsapp") || value?.sendAssessment?.includes("email")) &&
          filterValues?.selectedResources
        ) {
          bulkAssessmnet["sendAssessmentValue"] = value?.sendAssessment;
          value = {
            ...bulkAssessmnet,
          };
        }
        if (value.stage) {
          if (bulkAction === "Send Assessment" && assessmentSend) {
            setSendAssessmentModel(false);
            setAssessmentSendModel(true);
            setBulkAssessment(value);
          } else if (filterValues) {
            await fetch.post(`bulkAction`, { filterValues, bulkAction, id, value });
            showToast(`${bulkAction} successfully`);
            setLocalStorageFilter(true, parentSelectedTab, childRef, key);
            setSendAssessmentModel(false);
            setAssessmentSendModel(false);
          }
        }
      } else if (stepCount === 2) {
        const chunkSize = 10;
        let TotalUpload = 0,
          SuccessUpload = 0,
          FailUpload = 0;
        for (let i = 0; i < isUploadCandidate.length; i += chunkSize) {
          const chunk = isUploadCandidate.slice(i, i + chunkSize);
          let response = await fetch.post("/candidates", { candidates: chunk, header: value });
          TotalUpload = TotalUpload + response?.data?.totalOperations;
          SuccessUpload = SuccessUpload + response?.data?.successfulOperations;
          FailUpload = FailUpload + response?.data?.failedOperations;
        }
        setCandidateTotalUpload(TotalUpload);
        setCandidateSuccessUpload(SuccessUpload);
        SetCandidateFailUpload(FailUpload);
        setLocalStorageFilter(true, parentSelectedTab, childRef, key);
        showToast(`Candidates Upload successfully`);
        setStepCount(stepCount + 1);
      } else if (value?.fatchCandidate) {
        let data = {
          keySkills: jobData?.keySkills,
          score: value?.score,
          feedbackRate: value?.feedbackRate,
          totalExperiance: value?.totalExperiance,
          workMode: value?.workMode,
          isStar: value?.isStar[0],
        };
        setFatchDataNumber(value?.fatchCandidate);
        setFatchSearchValue(data);
        setIsFetchCandidateModelOpen(false);
        setFatchData(true);
        // await fetch.post("/fatchCandidate", {
        //   jobId: id,
        //   value,
        // });
      } else {
        await fetch.post("/candidate", {
          jobId: jobData?._id,
          ...value,
        });
        actionType === "add"
          ? showToast("Candidate Add successfully")
          : showToast("Candidate Updated successfully");
        setCandidateFormValues(candidateDetailsInitialValues);
        setLocalStorageFilter(true, parentSelectedTab, childRef, key);
        setaddCandidate(true);
      }

      // setIsCandidateModelOpen(false);
    },
    [
      childRef,
      actionType,
      parentSelectedTab,
      stepCount,
      candidateTotalUpload,
      candidateTotalUpload,
      candidateFailUpload,
      bulkAction,
      assessmentSend,
      candiateData,
      sendAssessment,
      jobData,
    ]
  );

  const handleButtonClick = () => {
    setIsCandidateModelOpen(true);
    setActionType("add");
  };

  const handleImportCandidate = async () => {
    setIsImportCandidateModelOpen(true);
    setSelectedFileName("");
  };

  const handleFetchCandidate = async () => {
    setIsFetchCandidateModelOpen(true);
  };

  const handleBulkModalClose = () => {
    setStepCount(1);
    setIsUploadCandidate(null);
    setIsImportCandidateModelOpen(false);
  };

  const downlodCsv = useCallback(async () => {
    const res = await fetch.get(`/bulkUpload`);
    const bulkCandidateData = res.data.rows;
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(bulkCandidateData[0]).join(",") +
      "\n" +
      bulkCandidateData.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sampleCandidateData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showToast("Download successfully");
  }, []);

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
    setCandidateFormValues({ ...candidateDetailsInitialValues });
  };

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      if (candidateToDelete) {
        await fetch.delete(`/candidate/${candidateToDelete._id}`);
        childRef.current.fetchData();
        showToast("Candidate Delete successfully");
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [candidateToDelete, childRef]);

  const handleDeleteCanceled = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleRowClick = (row) => {
    navigate(`/candidate/${row._id}?tabindex=${parentSelectedTab}`);
  };

  const handleRangeChange = (values) => {
    setTotalQuestions(values.totalQuestions);
    setcustomQuestion(values.customQuestion);
  };

  const handleAssessment = (values) => {
    const lastOption = bulkoptions[bulkoptions.length - 1];
    setIsLastStage(lastOption.value);
    setNextRound(values.sendAssessment === "NextRound" ? true : false);
    setNextSendAssessment(values.sendAssessment === "NextSendAssessment" ? true : false);
    if (values.sendAssessment === "SendAssessmentAll" || values.sendAssessment === "NextSendAssessment") {
      setAssessmentSend(true);
    }
  };

  const handleSendAssessment = useCallback(async () => {
    setIsSendingAssessment(true);
    await fetch.post(`/assessment`, {
      ...selectedCandidate,
      fromEmail: userProfileData?.email,
      jobTitle: jobData?.jobTitle,
      jobDescription: jobData?.jobDescription,
      customQuestion: jobData?.customQuestion,
      totalQuestions: totalQuestions,
      assessmentStatus: true,
      customQuestionPosition: customQuestion,
      orgId: userProfileData?.currentOrganization.orgId,
    });
    setLocalStorageFilter(true, parentSelectedTab, childRef, key);

    setIsSendingAssessment(false);
    setTotalQuestions(10);

    showToast("Sent Assessment successfully");

    setAssessmentModalOpen(false);
  }, [jobData, userProfileData, selectedCandidate, totalQuestions, parentSelectedTab, childRef]);

  const handlePremiumModelClose = () => {
    setIsPremiumModelOpen(false);
  };

  const handleBackClick = useCallback(() => {
    removeSessionStorageItem("candidateList");
    navigate(`/jobs`);
  }, []);

  const handleBulkDelete = useCallback(async () => {
    if (filterValues) {
      await fetch.post(`bulkAction`, { filterValues, bulkAction, id });
    }
    showToast(`${bulkAction} successfully`);
    setLocalStorageFilter(true, parentSelectedTab, childRef, key);
    setShowConfirmation(false);
  }, [filterValues]);

  const handleCandidatesImport = useCallback(
    (e) => {
      if (stepCount === 2) {
        formRef.current.handleSubmit();
      } else {
        e.preventDefault();
        if (file) {
          Papa.parse(file, {
            complete: async (result) => {
              const headers = result.data[0];
              const detailsArray = [
                { value: "none", label: "None" },
                ...Object.keys(headers).map((key) => ({
                  value: key,
                  label: key,
                })),
              ];
              setOptions(detailsArray);
              const candidatesWithJobId = result.data.map((candidate) => ({
                ...candidate,
                jobId: jobData._id,
              }));
              setIsUploadCandidate(candidatesWithJobId);
              setStepCount(stepCount + 1);
            },
            header: true,
            skipEmptyLines: true,
          });
        }
      }
    },
    [parentSelectedTab, childRef, file, stepCount]
  );

  const handleFileUpload = useCallback(
    async (e) => {
      setFile(e.target.files[0]);
      const selectedFile = e.target.files[0];
      setSelectedFileName(selectedFile.name);
    },
    [childRef, jobData]
  );

  const closeAssessmentModal = () => {
    if (!isSendingAssessment) {
      setAssessmentModalOpen(false);
      setTotalQuestions(10);
    }
  };

  if (!jobData) {
    return (
      <div style={{ height: "100px" }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    );
  }

  return (
    <Page
      fullWidth={true}
      title={jobData?.jobTitle}
      titleMetadata={
        <InlineStack gap={200} blockAlign="center">
          <StarJob jobData={jobData} width={"21px"}></StarJob>
        </InlineStack>
      }
      backAction={{ content: "Back", onAction: handleBackClick }}
    >
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <InlineStack>
          {notificationsNotRead > 0 && <div className="notificationCount"> {notificationsNotRead}</div>}
        </InlineStack>
        {selected === 0 && (
          <>
            <Box paddingBlockEnd="300">
              <InlineStack align="end" gap="400">
                <AccessPermission access={["manageJobs", "manageCandidate"]}>
                  <Button size="large" disabled={!jobData.isActive} onClick={handleFetchCandidate}>
                    Fetch Candidates
                  </Button>
                  <ImportBulkResume jobData={jobData} resumeCount={resumeCount} />
                  {jobData?.stages?.length < 1 && jobData.isActive ? (
                    <Tooltip content="Please add Interview Round first">
                      <Button size="large" onClick={handleButtonClick} disabled={true}>
                        Add Candidate
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip content="Manually add candidate details.">
                      <Button size="large" disabled={!jobData.isActive} onClick={handleButtonClick}>
                        Add Candidate
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip content="Import Candidate Details in Bulk Using CSV Files">
                    <Button size="large" disabled={!jobData.isActive} onClick={handleImportCandidate}>
                      Import Candidates
                    </Button>
                  </Tooltip>

                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e)}
                    ref={fileInputRef}
                  />
                  {/* <Button size="large" onClick={handleImportCandidate}>
                    Import Candidates
                  </Button> */}
                  <input
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileUpload(e)}
                    ref={fileInputRef}
                  />
                </AccessPermission>
              </InlineStack>
            </Box>
            <BlockStack gap="200">
              <CandidateDetails
                addCandidate={addCandidate}
                childRef={childRef}
                loading={loading}
                setLoading={setLoading}
                fatchsearchValue={fatchsearchValue}
                fetchDataNumber={fetchDataNumber}
              ></CandidateDetails>
            </BlockStack>
            <BannerModal
              size={"large"}
              open={isCandidateModelOpen}
              onClose={handleModalClose}
              title={actionType === "update" ? "Edit Candidate" : "Add New Candidate"}
              primaryAction={{
                content: "Submit",
                onAction: saveJob,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handleModalClose,
                },
              ]}
              children={
                <CommonForm
                  onSubmit={handleSubmit}
                  formRef={formRef}
                  initialValues={candidateFormValues}
                  formFields={getCandidateDetailFields(jobData)}
                  title="Add New Candidate"
                  isSave={false}
                />
              }
            />
            <BannerModal
              open={isPremiumModelOpen}
              onClose={handlePremiumModelClose}
              title={"Upgrade to Premium"}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handlePremiumModelClose,
                },
              ]}
              children={<Plan config={pricingConfig} />}
              size={"large"}
            />
            <Modal
              open={isDeleteModalOpen}
              onClose={handleDeleteCanceled}
              title="Confirm Delete"
              primaryAction={{
                content: "Delete",
                onAction: handleDeleteConfirmed,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: handleDeleteCanceled,
                },
              ]}
            >
              <Modal.Section>
                <Text>Are you sure you want to delete this candidate?</Text>
              </Modal.Section>
            </Modal>
            <BannerModal
              open={isAssessmentModalOpen}
              onClose={closeAssessmentModal}
              title={"Send Assessment"}
              primaryAction={{
                content: "Send",
                onAction: handleSendAssessment,
                disabled: isSendingAssessment,
              }}
              secondaryActions={[
                {
                  content: "Cancel",
                  onAction: closeAssessmentModal,
                  disabled: isSendingAssessment,
                },
              ]}
              children={
                <BlockStack gap="200">
                  <Text>Send To: {selectedCandidate?.fullName}</Text>
                  <Text>Custom Question: {jobData?.customQuestion?.length}</Text>
                  <Text>AI generated Question: {totalQuestions - jobData?.customQuestion?.length}</Text>
                  <CommonForm
                    formRef={customQuestionsformRef}
                    initialValues={TotalQuestionsInitialValues}
                    formFields={TotalQuestionsFields}
                    title="Total Questions"
                    isSave={false}
                    onFormChange={handleRangeChange}
                  />
                </BlockStack>
              }
            />
            <BannerModal
              open={isImportCandidateModelOpen}
              onClose={handleBulkModalClose}
              title={stepCount == 3 ? "Candiate Upload Summary" : "Bulk Candidate Upload"}
              primaryAction={
                stepCount < 3
                  ? {
                      content: "Next",
                      onAction: handleCandidatesImport,
                    }
                  : ""
              }
              onClick={
                stepCount < 3
                  ? (e) => {
                      handleCandidatesImport(e);
                    }
                  : ""
              }
              secondaryActions={
                stepCount < 3
                  ? [
                      {
                        content: "Cancel",
                        onAction: handleBulkModalClose,
                      },
                    ]
                  : ""
              }
              children={
                <>
                  {stepCount == 1 && (
                    <BlockStack gap="500">
                      <InlineStack gap={200}>
                        <Text align="center" as="p" fontWeight="bold">
                          Sample of Candidate import CSV file{" "}
                        </Text>
                        <Button variant="plain" onClick={downlodCsv}>
                          Download
                        </Button>
                      </InlineStack>
                      <InlineStack align="space-between" blockAlign="center">
                        <Text align="center" as="p" fontWeight="bold">
                          Import CSV File{" "}
                        </Text>
                        <div>
                          <label>{selectedFileName ? selectedFileName : "No file chosen"}</label>
                          <div className="custom-file-input">
                            <input type="file" id="csvFileInput" accept=".csv" onChange={handleFileUpload} />
                            <label htmlFor="csvFileInput" className="file-input-label">
                              Choose CSV File
                            </label>
                          </div>
                        </div>
                      </InlineStack>
                    </BlockStack>
                  )}
                  {stepCount == 2 && (
                    <div className="importCandidate">
                      <CommonForm
                        onSubmit={handleSubmit}
                        formRef={formRef}
                        initialValues={candidateDetailsInitialValues}
                        formFields={getImportCandidateDetailFields(options)}
                        isSave={false}
                      />
                    </div>
                  )}
                  {stepCount == 3 && (
                    <BlockStack gap="500">
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Total Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateTotalUpload}
                        </Text>
                      </InlineStack>
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Successful Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateSuccessUpload}
                        </Text>
                      </InlineStack>
                      <InlineStack gap={200}>
                        <Text align="center" as="p">
                          Failed Candidates to Upload :
                        </Text>
                        <Text align="center" as="p" fontWeight="bold">
                          {candidateFailUpload}
                        </Text>
                      </InlineStack>
                      <Text align="center" as="p" fontWeight="bold">
                        Out of {candidateTotalUpload} Candidates , {candidateSuccessUpload} candidates successfully
                        completed the process, while {candidateFailUpload} did not meet the requirements.
                      </Text>
                    </BlockStack>
                  )}
                </>
              }
            />
          </>
        )}
        {selected === 1 && <Stage jobId={jobData._id} />}
        {selected === 2 && <AssessmentReminder status={"joblevel"} id={jobData._id}></AssessmentReminder>}
        {selected === 3 && (
          <Notifications jobData={jobData} setNotificationsNotRead={setNotificationsNotRead}></Notifications>
        )}
      </Tabs>
      <Modal
        open={showConfirmation}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkDeleteClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkDelete,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkDeleteClose,
          },
        ]}
        size="small"
      >
        <Modal.Section>
          <Text>Are you sure you want to {bulkAction} Candidates?</Text>
        </Modal.Section>
      </Modal>
      <Modal
        open={sendAssessmentModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessment,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={bulkSendAssessmentInitialValues}
            formFields={bulkSendAssessmentFormFields(bulkoptions, NextRound, NextSendAssessment, isLastStage)}
            isSave={false}
            onFormChange={handleAssessment}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={assessmentSendModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendAssessmentClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessmentMassage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendAssessmentClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={isSendAssessment}
        onClose={handleSendAssessmentCanceled}
        title="Send Assessment Method"
        primaryAction={{
          content: "Send",
          onAction: handleSenAssessmentConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSendAssessmentCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={isFetchCandidateModelOpen}
        onClose={handleSendAssessmentCanceled}
        title="Fatch Candidate"
        primaryAction={{
          content: "Fatch",
          onAction: handleFatchCndidate,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSendAssessmentCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={fetchCandidateInitialValue}
            formFields={fetchCandidate}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </Page>
  );
};
