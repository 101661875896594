import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Frame,
  Image,
  IndexTable,
  InlineStack,
  Link,
  Loading,
  Modal,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { DeleteMinor, EditMinor } from "@shopify/polaris-icons";
import { formatDistanceToNow } from "date-fns";
import { getCountryCallingCode } from "libphonenumber-js";
import { useAuthenticatedFetch } from "@/Api/Axios";
import { Whatsapp } from "@/Assets/Index";
import { BannerModal } from "@/Components/Common/BannerModal";
import CommonForm from "@/Components/Common/CommonForm";
import CommonTable from "@/Components/Common/CommonTable/CommonTable";
import ProgressCircle from "@/Components/Common/ProgressCircle";
import { ToastContext } from "@/Context/ToastContext";
import { logger } from "@/Services/Logger/Index";
import { setLocalStorageFilter } from "@/Utils/AppUtils.js";
import {
  bulkSendAssessmentFormFields,
  bulkSendAssessmentInitialValues,
  candidateDetailsInitialValues,
  getCandidateDetailFields,
  getFilterField,
  sendAssessmentFields,
  sendAssessmentInitialValues,
  totalExperience,
} from "../../../Assets/Mocks/Candidate.mock";
import { TotalQuestionsFields, TotalQuestionsInitialValues } from "../../../Assets/Mocks/ToalQuestion.mock";
import { AccessPermission } from "../../../Components/Common/AccessPermission";
import StarCandidate from "../../../Components/Common/StarCandidate";
import { UserContext } from "../../../Context/UserContext";
import { getSessionStorageItem, setSessionStorageItem } from "../../../Utils/Index.js";
import Plan from "../../Plan/Plan.jsx";
import CandidateAction from "../Candidate/CandidateAction";
import CandidateStatus from "../Candidate/CandidateStatus";
import AssessmentStatus from "./AssessmentStatus";
import ShowResumeScore from "./ShowResumeScore.jsx";

export const CandidateDetails = ({
  sourcing,
  searchValue,
  addCandidate,
  childRef,
  loading,
  setLoading,
  fatchsearchValue,
  fetchDataNumber,
}) => {
  const { userProfileData } = useContext(UserContext);
  const [isCandidateModelOpen, setIsCandidateModelOpen] = useState(false);
  const [candidateFormValues, setCandidateFormValues] = useState(candidateDetailsInitialValues);
  const [actionType, setActionType] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [candidateToDelete, setCandidateToDelete] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [totalQuestions, setTotalQuestions] = useState(TotalQuestionsInitialValues.totalQuestions);
  const [customQuestion, setcustomQuestion] = useState(TotalQuestionsInitialValues.customQuestion);
  const [isAssessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [isPremiumModelOpen, setIsPremiumModelOpen] = useState(false);
  const [isSendingAssessment, setIsSendingAssessment] = useState(false);
  const { showToast } = useContext(ToastContext);
  const [jobData, setJobData] = useState();
  const formRef = useRef();
  const customQuestionsformRef = useRef();
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [pagination, setParentPagination] = useState(null);
  const [stageTabs, setStageTabs] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [parentSelectedTab, setParentSelectedTab] = useState(urlParams.get("tabindex") || 0);
  const key = "candidateList";
  const [resumeCount, setResumeCount] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [bulkAction, setBulkAction] = useState();
  const [clearSelection, setClearSelection] = useState(false);
  const [sendAssessmentModel, setSendAssessmentModel] = useState(false);
  const [NextRound, setNextRound] = useState(false);
  const [isLastStage, setIsLastStage] = useState(false);
  const [NextSendAssessment, setNextSendAssessment] = useState(false);
  const [bulkoptions, setBulkOptions] = useState(null);
  const [assessmentSend, setAssessmentSend] = useState(false);
  const [assessmentSendModel, setAssessmentSendModel] = useState(false);
  const [bulkAssessmnet, setBulkAssessment] = useState();
  const [sendAssessment, setsendAssessment] = useState(false);
  const [candiateData, setcandiateData] = useState();
  const [isSendAssessment, setIsSendAssessment] = useState(false);
  const [notificationsNotRead, setNotificationsNotRead] = useState(0);

  const pricingConfig = {
    hideHeader: true,
    plans: ["Free", "Premium"],
  };

  const handleBulkDeleteClose = useCallback(() => setShowConfirmation(!showConfirmation), [showConfirmation]);
  const handleBulkSendClose = useCallback(
    () => setSendAssessmentModel(!sendAssessmentModel),
    [sendAssessmentModel]
  );

  const handleBulkSendAssessmentClose = useCallback(
    () => setAssessmentSendModel(!assessmentSendModel),
    [assessmentSendModel]
  );

  useEffect(() => {
    if (sourcing) {
      setLoading(true);
      setLocalStorageFilters(true, searchValue, childRef);
    } else if (fatchsearchValue) {
      setLoading(true);
      setLocalStorageFilters(true, fatchsearchValue, childRef);
    } else {
      getJobData();
      setLocalStorageFilter(false, parentSelectedTab, childRef, key);
      setLoading(false);
    }
  }, [searchValue, sourcing, fatchsearchValue]);

  useEffect(() => {
    if (addCandidate) {
      setLocalStorageFilter(true, parentSelectedTab, childRef, key);
    }
  }, [addCandidate]);

  useEffect(() => {
    if (sendAssessment) {
      setIsSendAssessment(true);
    }
  }, [sendAssessment]);

  const handleSendAssessmentCanceled = useCallback(() => {
    setIsSendAssessment(false);
    setsendAssessment(false);
  }, []);

  const handleSenAssessmentConfirmed = useCallback(async () => {
    if (formRef.current && sendAssessment) {
      formRef.current.handleSubmit();
    }
  }, [candiateData, sendAssessment]);

  const saveJob = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleBulkSendAssessment = useCallback(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction]);

  const handleBulkSendAssessmentMassage = useCallback(() => {
    setAssessmentSend(false);
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef, filterValues, bulkAction, assessmentSend]);

  useEffect(() => {}, [parentSelectedTab, notificationsNotRead]);

  const getJobData = useCallback(async () => {
    localStorage.setItem("playbackSpeed", 1);
    if (!sourcing) {
      const stagesData = await fetch.get(`/stages/${id}`);
      const stageTitles = stagesData?.data?.rows?.map((item) => ({
        value: item._id,
        label: item.stageDetails.stageTitle,
      }));
      setBulkOptions(stageTitles);
      const candidateTab = document.querySelector(".Polaris-Tabs__TabContainer #candidates");
      if (stagesData?.data?.rows?.length > 0) {
      } else {
        document.querySelector("li #candidates")?.classList?.toggle("hidden");
      }

      const mappedTabs = stagesData?.data?.rows?.map((stage, index) => ({
        id: `${stage?.stageDetails?.stageTitle}-${index}`,
        content: stage?.stageDetails?.stageTitle,
        searchQuery: {
          key: "lastStage._id",
          value: stage?._id,
          tabQuery: true,
          type: "marketQuery",
          secondQuery: [{ reject: false }],
        },
        hideFilter: ["reject"],
      }));
      const stageTabs = [{ id: "all", content: "All", hideFilter: [] }];
      if (mappedTabs && mappedTabs.length > 0) {
        stageTabs.push(...mappedTabs);
        stageTabs.push(
          {
            id: "reject",
            content: "Reject",
            searchQuery: { key: "reject", value: true, tabQuery: true },
            hideFilter: ["reject"],
          },
          {
            id: "Shortlist",
            content: "Shortlist",
            searchQuery: { key: "isShortList", value: true, tabQuery: true },
            hideFilter: ["reject"],
          }
        );
      }
      setStageTabs(stageTabs);
      const res = await fetch.get(`/job/${id}`);
      setJobData(res.data);
      const notifications = await fetch.post(`/getNotifications?page=${1}&pageSize=${10}`, res?.data);
      setNotificationsNotRead(notifications?.data?.unreadCount);
    }
  }, [id, setJobData, notificationsNotRead]);

  const handleSubmit = useCallback(
    async (value) => {
      if (candiateData?._id && sendAssessment) {
        setIsSendingAssessment(true);
        await fetch.post("/stage/sendAssessment", {
          jobId: candiateData?.jobId,
          candidateId: candiateData?._id,
          stageId: candiateData.currentStage?._id,
          sendAssessmentType: value,
        });
        setsendAssessment(false);
        setIsSendingAssessment(false);
        setIsSendAssessment(false);
        childRef?.current?.fetchData();
        showToast("Assessment sent successfully");
      } else if (filterValues?.selectedResources) {
        if (
          (value?.sendAssessment?.includes("whatsapp") || value?.sendAssessment?.includes("email")) &&
          filterValues?.selectedResources
        ) {
          bulkAssessmnet["sendAssessmentValue"] = value?.sendAssessment;
          value = {
            ...bulkAssessmnet,
          };
        }
        if (value.stage) {
          if (bulkAction === "Send Assessment" && assessmentSend) {
            setSendAssessmentModel(false);
            setAssessmentSendModel(true);
            setBulkAssessment(value);
          } else if (filterValues) {
            await fetch.post(`bulkAction`, { filterValues, bulkAction, id, value });
            showToast(`${bulkAction} successfully`);

            setClearSelection(true);
            if (sourcing) {
              setLocalStorageFilters(true, searchValue, childRef);
            } else {
              setLocalStorageFilter(true, parentSelectedTab, childRef, key);
            }
            setSendAssessmentModel(false);
            setAssessmentSendModel(false);
          }
        }
      } else {
        await fetch.post("/candidate", {
          jobId: jobData?._id,
          ...value,
        });
        actionType === "add"
          ? showToast("Candidate Add successfully")
          : showToast("Candidate Updated successfully");
        setCandidateFormValues(candidateDetailsInitialValues);
        if (sourcing) {
          setLocalStorageFilters(true, searchValue, childRef);
        } else {
          setLocalStorageFilter(true, parentSelectedTab, childRef, key);
        }
      }

      setIsCandidateModelOpen(false);
    },
    [childRef, actionType, parentSelectedTab, bulkAction, assessmentSend, candiateData, sendAssessment]
  );

  const handleModalClose = () => {
    setIsCandidateModelOpen(false);
    setCandidateFormValues({ ...candidateDetailsInitialValues });
  };

  const handleEdit = (row) => {
    row.totalExperience = row?.totalExperience?.toString();
    setActionType("update");
    const rowData = JSON.parse(JSON.stringify(row, (key, value) => (value == null ? undefined : value)));
    setCandidateFormValues({ ...candidateDetailsInitialValues, ...rowData });
    setIsCandidateModelOpen(true);
  };

  const handleDelete = useCallback(async (row) => {
    setCandidateToDelete(row);
    setIsDeleteModalOpen(true);
  }, []);

  const handleDeleteConfirmed = useCallback(async () => {
    try {
      if (candidateToDelete) {
        await fetch.delete(`/candidate/${candidateToDelete._id}`);
        childRef.current.fetchData();
        showToast("Candidate Delete successfully");
      }
    } catch (error) {
      logger.error(error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [candidateToDelete, childRef]);

  const handleDeleteCanceled = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleRowClick = (row) => {
    navigate(`/candidate/${row._id}?tabindex=${parentSelectedTab}`);
  };

  const handleRangeChange = (values) => {
    setTotalQuestions(values.totalQuestions);
    setcustomQuestion(values.customQuestion);
  };

  const handleAssessment = (values) => {
    const lastOption = bulkoptions[bulkoptions.length - 1];
    setIsLastStage(lastOption.value);
    setNextRound(values.sendAssessment === "NextRound" ? true : false);
    setNextSendAssessment(values.sendAssessment === "NextSendAssessment" ? true : false);
    if (values.sendAssessment === "SendAssessmentAll" || values.sendAssessment === "NextSendAssessment") {
      setAssessmentSend(true);
    }
  };

  const handleSendAssessment = useCallback(async () => {
    setIsSendingAssessment(true);
    await fetch.post(`/assessment`, {
      ...selectedCandidate,
      fromEmail: userProfileData?.email,
      jobTitle: jobData?.jobTitle,
      jobDescription: jobData?.jobDescription,
      customQuestion: jobData?.customQuestion,
      totalQuestions: totalQuestions,
      assessmentStatus: true,
      customQuestionPosition: customQuestion,
      orgId: userProfileData?.currentOrganization.orgId,
    });
    setLocalStorageFilter(true, parentSelectedTab, childRef, key);

    setIsSendingAssessment(false);
    setTotalQuestions(10);

    showToast("Sent Assessment successfully");

    setAssessmentModalOpen(false);
  }, [jobData, userProfileData, selectedCandidate, totalQuestions, parentSelectedTab, childRef]);

  const handlePremiumModelClose = () => {
    setIsPremiumModelOpen(false);
  };

  const filterFormFields = useMemo(() => getFilterField(), []);

  const handleMoveToNextStage = useCallback(
    async (row) => {
      await fetch.post("/movecandidate", {
        row: row,
        jobId: sourcing ? row?.jobDetails?.[0]?._id : jobData._id,
      });
      if (sourcing) {
        setLocalStorageFilters(true, searchValue, childRef);
      } else {
        setLocalStorageFilter(true, parentSelectedTab, childRef, key);
      }
    },
    [jobData, parentSelectedTab, childRef]
  );

  const formatIndianNumber = (number) => {
    const numberString = number.toString();
    const lastTwo = numberString.slice(-3);
    const otherNumbers = numberString.slice(0, -3);

    let formattedNumber;

    if (otherNumbers !== "") {
      const reversedOtherNumbers = otherNumbers.split("").reverse().join("");
      const formattedReversedOtherNumbers = reversedOtherNumbers
        .replace(/(\d{2})(?=\d)/g, "$1,")
        .split("")
        .reverse()
        .join("");
      formattedNumber = formattedReversedOtherNumbers + "," + lastTwo;
    } else {
      formattedNumber = lastTwo;
    }

    return formattedNumber;
  };

  const formatTimeAgo = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } else {
      return "N/A";
    }
  };

  const getExperienceLabel = (value) => {
    const experience = totalExperience.find((option) => option.value === value.toString());
    return experience ? experience.label : "Unknown";
  };

  const handleBulkDelete = useCallback(async () => {
    if (filterValues) {
      await fetch.post(`bulkAction`, { filterValues, bulkAction, id });
    }
    showToast(`${bulkAction} successfully`);
    setClearSelection(true);
    if (sourcing) {
      setLocalStorageFilters(true, searchValue, childRef);
    } else {
      setLocalStorageFilter(true, parentSelectedTab, childRef, key);
    }
    setShowConfirmation(false);
  }, [filterValues]);

  const addCandidatetoJob = useCallback(
    async (row) => {
      let jobId = Array.isArray(row.addJobId) ? row.addJobId : [];

      if (!jobId.includes(id)) {
        jobId.push(id);
      }

      row.addJobId = jobId;
      row["addjobCandidate"] = true;
      row["condodateJobId"] = id;
      await fetch.post("/candidate", {
        ...row,
      });
      showToast(`add candidate successfully`);
      setLocalStorageFilters(true, searchValue, childRef);
    },
    [filterValues]
  );

  const handleIconClick = async (row, stage) => {
    let assessment = row.assessment[0];
    const now = new Date();
    const hoursDifference = Math.floor((now - new Date(assessment?.updated)) / (1000 * 60 * 60));
    const remainingHours = 48 - hoursDifference;
    const org = await fetch.get(`/organization/${row?.orgId}`);
    let countryCode = org?.data?.country;
    const callingCode = getCountryCallingCode(countryCode);
    const message = `Hello ${row?.fullName},

Thanks for applying for the ${jobData?.jobTitle} role at 48 hours! 

We’d like to invite you to complete an assessment as the next step.

Job Details:
Job Title: ${sourcing ? row?.jobDetails?.[0]?.jobTitle : jobData?.jobTitle}
Organization: ${sourcing ? userProfileData?.currentOrganization?.name : org.name}
Location: ${sourcing ? row?.jobDetails?.[0]?.jobLocation : jobData?.jobLocation}
Position : ${row?.employmentType ? row?.employmentType : "N/A"}
Expire In : ${remainingHours < 0 ? "48" : remainingHours} hours
Question Types : Video, Text, Number

Note: You can take the assessment on your mobile or laptop no sign-up needed.

Link: ${process.env.APP_URL}/assessment/${stage?.assessmentId}`; // Your pre-filled message

    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${row?.mobile}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  const createRowsData = useCallback(
    (rows, selectedResources) => {
      let jobDetail;
      const startIndex = pagination?.pageSize * (pagination?.page - 1) || 0;
      if (rows?.length <= 0) return [];
      rows?.map((row, index) => {
        row["id"] = row._id;
        if (row?.stages?.length > 0) {
          row.currentStage = row?.stages[row?.stages?.length - 1];
          row.lastStage = row?.stages[row?.stages?.length - 2];
        }
        if (sourcing) {
          jobDetail = row?.jobDetails[0];
        }
        const currentJobStages = row?.stages?.filter((e) =>
          e?.jobId == sourcing ? jobDetail?._id : jobData?._id
        );
        if (currentJobStages?.length > 0 && row?.stages?.length > 0) {
          row.inLastStage = sourcing
            ? row?.stages?.[row?.stages?.length - 1]?.["_id"] ===
              jobDetail?.stageDetails?.[jobDetail?.stageDetails?.length - 1]?.["_id"]
            : row?.stages?.[row?.stages?.length - 1]?.["_id"] ===
              jobData?.stages?.[jobData?.stages?.length - 1]?.["_id"];
        }
      });
      return rows?.map((row, index) => {
        return (
          <IndexTable.Row
            id={row._id}
            selected={selectedResources.includes(row._id)}
            key={row._id}
            position={index}
            onClick={() => {}}
          >
            <IndexTable.Cell>{index + 1 + startIndex}</IndexTable.Cell>
            <IndexTable.Cell key={row._id + 1}>
              <div style={{ width: "250px" }}>
                <BlockStack gap="200">
                  {!sourcing && row?.assessment?.[0]?.imageUrl && (
                    <Image
                      width={80}
                      source={`https://${process.env.VULTR_BUCKETNAME}.ewr1.vultrobjects.com/${row?.assessment[0]?.imageUrl}`}
                    />
                  )}
                  <InlineStack gap={100}>
                    <Link onClick={() => handleRowClick(row)} removeUnderline monochrome>
                      <InlineStack gap="200">
                        <Text variant="headingMd" as="h6">
                          {row?.fullName || "N/A"}
                        </Text>
                        {row?.reject && <Badge tone="critical">Rejected</Badge>}
                        {row?.isShortList && <Badge tone="success">Shortlisted</Badge>}
                      </InlineStack>
                    </Link>
                    <StarCandidate candidate={row} width={"20px"}></StarCandidate>
                    {row?.candidateRate && (
                      <ProgressCircle
                        score={row?.candidateRate}
                        fontSize="8px"
                        width="23px"
                        border="2px"
                        progress={true}
                      />
                    )}
                  </InlineStack>
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Email :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.email || "N/A"}
                    </Text>
                  </InlineStack>
                  {row?.mobile && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Mobile :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.mobile}
                      </Text>
                      {row?.assessment?.length > 0 && (
                        <a
                          style={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
                          onClick={() => handleIconClick(row, row?.currentStage)}
                        >
                          <Image width={15} source={Whatsapp} />
                        </a>
                      )}
                    </InlineStack>
                  )}
                  {row?.currentLocation && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Location :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.currentLocation}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.candidateFeedback?.length > 0 && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Last Feedback :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.candidateFeedback[row?.candidateFeedback.length - 1]?.feedback}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.resume?.assetUrl && (
                    <InlineStack gap="200" blockAlign="center">
                      {row?.resumeScore?.score >= 0 && (
                        <ProgressCircle
                          score={parseInt(row?.resumeScore?.score)}
                          width="55px"
                          border="5px"
                          fontSize="12px"
                        />
                      )}

                      <ShowResumeScore row={row} childRef={childRef} jobData={sourcing ? jobDetail : jobData} />
                    </InlineStack>
                  )}
                </BlockStack>
              </div>
            </IndexTable.Cell>

            <IndexTable.Cell>
              <div style={{ width: "200px" }}>
                <BlockStack gap="200">
                  {/* <InlineStack gap="200"> */}
                  {row?.totalExperience && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Experience :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {getExperienceLabel(row.totalExperience)}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.applyingAs && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Applying As :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.applyingAs}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.preferWorkMode && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Work Mode :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.preferWorkMode}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.noticePeriod && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Notice Period :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.noticePeriod}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.expectedCTC && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Expected CTC :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.expectedCTC ? formatIndianNumber(row?.expectedCTC) : "-"}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.currentCTC && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Current CTC :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.currentCTC ? formatIndianNumber(row?.currentCTC) : "-"}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.isLocation && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Relocatable :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.isLocation}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.candidateTitle && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Candidate Title :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.candidateTitle}
                      </Text>
                    </InlineStack>
                  )}
                  {row?.skills && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Skills :
                      </Text>
                      <div style={{ maxWidth: "280px", whiteSpace: "normal", overflowWrap: "break-word" }}>
                        <Text as="p" fontWeight="medium">
                          {row?.skills}
                        </Text>
                      </div>
                    </InlineStack>
                  )}
                  {row?.developerType && (
                    <InlineStack gap="200">
                      <Text as="p" tone="subdued">
                        Employment Type :
                      </Text>
                      <Text as="p" fontWeight="medium">
                        {row?.developerType}
                      </Text>
                    </InlineStack>
                  )}
                </BlockStack>
              </div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created By :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {row.isOnline ? "Public Link" : row?.userdetail?.[0]?.firstName}
                  </Text>
                </InlineStack>
                <InlineStack gap="200">
                  <Text as="p" tone="subdued">
                    Created At :
                  </Text>
                  <Text as="p" fontWeight="medium">
                    {formatTimeAgo(row?.created)}
                  </Text>
                </InlineStack>
                {row?.platform && (
                  <InlineStack gap="200">
                    <Text as="p" tone="subdued">
                      Platform :
                    </Text>
                    <Text as="p" fontWeight="medium">
                      {row?.platform}
                    </Text>
                  </InlineStack>
                )}
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {row?.jobId ? (
                <div style={{ width: "300px", padding: "10px 0px" }}>
                  <BlockStack gap="200">
                    {row?.stages?.length > 1 && (
                      <Box padding="200" borderColor="border" borderWidth="025" shadow="200" borderRadius="100">
                        <BlockStack gap="200">
                          <Text as="p" tone="subdued">
                            Past Assessment :
                          </Text>
                          <InlineStack gap="200">
                            <BlockStack gap="200">
                              {row?.stages?.slice(0, -1).map((stage) => (
                                <InlineStack gap="200" blockAlign="center" key={stage._id}>
                                  <Text as="p" fontWeight="medium">
                                    {stage.stageDetails.stageTitle} {":"}
                                  </Text>
                                  <AssessmentStatus
                                    row={row}
                                    stage={stage}
                                    jobData={sourcing ? jobDetail : jobData}
                                  ></AssessmentStatus>
                                </InlineStack>
                              ))}
                            </BlockStack>
                          </InlineStack>
                        </BlockStack>
                      </Box>
                    )}

                    <Box padding="400" borderColor="border" borderWidth="025" shadow="200" borderRadius="100">
                      <BlockStack gap="200">
                        <BlockStack gap="200">
                          <InlineStack gap="200">
                            <Text as="p" tone="subdued">
                              Current Interview Round :
                            </Text>
                            <Text as="p" fontWeight="medium">
                              {row?.currentStage?.["stageDetails"]["stageTitle"]}{" "}
                            </Text>
                          </InlineStack>
                          <InlineStack gap="200" blockAlign="center">
                            <Text as="p" tone="subdued">
                              Status :
                            </Text>
                            <AssessmentStatus
                              row={row}
                              stage={row?.currentStage}
                              jobData={sourcing ? jobDetail : jobData}
                            ></AssessmentStatus>
                          </InlineStack>

                          <InlineStack gap="200">
                            {!row?.reject && (
                              <>
                                {!row?.currentStage?.assessmentStatus && (
                                  <InlineStack gap="200">
                                    {!row?.currentStage?.assessmentStatus &&
                                      (jobDetail?.isActive || jobData?.isActive) && (
                                        <>
                                          {!row?.mobile && !row?.email ? (
                                            <Tooltip
                                              content={
                                                "Please provide either an email address or mobile number to send the assessment."
                                              }
                                            >
                                              <Button
                                                primary
                                                onClick={() => {
                                                  setsendAssessment(true);
                                                  setcandiateData(row);
                                                }}
                                                disabled={isSendingAssessment || (!row?.mobile && !row?.email)}
                                              >
                                                Send Assessment
                                              </Button>
                                            </Tooltip>
                                          ) : (
                                            <Button
                                              primary
                                              onClick={() => {
                                                setsendAssessment(true);
                                                setcandiateData(row);
                                              }}
                                              disabled={isSendingAssessment}
                                            >
                                              Send Assessment
                                            </Button>
                                          )}
                                        </>
                                      )}
                                  </InlineStack>
                                )}
                                {(jobDetail?.isActive || jobData?.isActive) && (
                                  <InlineStack gap="200">
                                    {!row?.inLastStage && row?.currentStage?.assessmentStatus == true && (
                                      <Button primary onClick={() => handleMoveToNextStage(row)}>
                                        Move to next Interview Round
                                      </Button>
                                    )}
                                  </InlineStack>
                                )}
                                {!row?.inLastStage &&
                                  !row?.currentStage?.assessmentStatus &&
                                  (jobDetail?.isActive || jobData?.isActive) && (
                                    <InlineStack gap="200">
                                      {!row?.currentStage?.assessmentStatus && (
                                        <Button primary onClick={() => handleMoveToNextStage(row)}>
                                          Skip this Interview Round
                                        </Button>
                                      )}
                                    </InlineStack>
                                  )}
                              </>
                            )}
                            {(jobData?.isActive || sourcing) && (
                              <CandidateStatus
                                candidate={row}
                                jobid={sourcing ? jobDetail?._id : jobData?._id}
                                parentSelectedTab={parentSelectedTab}
                                childRef={childRef}
                                filter={true}
                              ></CandidateStatus>
                            )}
                          </InlineStack>
                        </BlockStack>
                      </BlockStack>
                    </Box>
                  </BlockStack>
                </div>
              ) : (
                <Text>-</Text>
              )}
            </IndexTable.Cell>

            <IndexTable.Cell>
              <BlockStack gap="200">
                <InlineStack gap="100">
                  {(jobData?.isActive || sourcing) && (
                    <AccessPermission access={["manageJobs", "manageCandidate"]}>
                      <Button
                        plain
                        monochrome
                        icon={EditMinor}
                        onClick={() => {
                          handleEdit(row);
                        }}
                      />
                      <Button
                        plain
                        monochrome
                        icon={DeleteMinor}
                        onClick={() => {
                          handleDelete(row);
                        }}
                      />
                    </AccessPermission>
                  )}
                  {(jobData?.isActive || sourcing) && (
                    <AccessPermission access={["viewCandidate", "manageCandidate"]}>
                      <CandidateAction
                        candidate={row}
                        jobid={sourcing ? jobDetail?._id : jobData?._id}
                        parentSelectedTab={parentSelectedTab}
                        childRef={childRef}
                        filter={true}
                        jobData={sourcing ? jobDetail : jobData}
                      />
                    </AccessPermission>
                  )}
                  {fatchsearchValue && (
                    <AccessPermission access={["viewCandidate", "manageCandidate"]}>
                      <Button
                        plain
                        monochrome
                        onClick={() => {
                          addCandidatetoJob(row);
                        }}
                      >
                        Add Candidate
                      </Button>
                    </AccessPermission>
                  )}
                </InlineStack>
              </BlockStack>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      });
    },
    [userProfileData, childRef, handleSendAssessment, pagination, jobData, parentSelectedTab, fatchsearchValue]
  );

  const closeAssessmentModal = () => {
    if (!isSendingAssessment) {
      setAssessmentModalOpen(false);
      setTotalQuestions(10);
    }
  };

  const handleDeleteBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("Delete");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);
  const handleRejectBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("Reject");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);
  const handleShortListBannerOpen = useCallback((rows, selectedResources, allResourcesSelected, search) => {
    setBulkAction("ShortList");
    setShowConfirmation(true);
    setFilterValues({
      selectedResources,
      allResourcesSelected,
      search,
    });
    setClearSelection(false);
  }, []);

  const handleSendAssessmentBannerOpen = useCallback(
    (rows, selectedResources, allResourcesSelected, search) => {
      setBulkAction("Send Assessment");
      setSendAssessmentModel(true);
      setFilterValues({
        selectedResources,
        allResourcesSelected,
        search,
      });
      setClearSelection(false);
    },
    [bulkoptions]
  );

  const setLocalStorageFilters = (removeAllFilter, value, childRef) => {
    if (key) {
      const item = getSessionStorageItem(key);
      let { pagination, filter } = item || {};
      filter = [];
      const transformedFilter = Object.entries(value)
        .filter(([_, v]) => v !== "") // Exclude empty string values
        .map(([k, v]) => ({
          key: k,
          value: v,
          tabQuery: true,
        }));

      filter?.push(...transformedFilter); // Push the transformed values

      filter = !removeAllFilter ? filter?.filter((e) => e?.tabQuery == true) : filter;
      pagination = removeAllFilter ? { page: 1, pageSize: 10 } : pagination;
      setSessionStorageItem(key, { pagination, filter });
      childRef?.current && childRef?.current?.fetchData(pagination, filter);
      setLoading(false);
    }
  };

  if (loading) {
    return <></>;
  }

  if (!jobData && !sourcing) {
    return (
      <div style={{ height: "100px" }}>
        <Frame>
          <Loading />
        </Frame>
      </div>
    );
  }

  return (
    <>
      <div>
        <BlockStack gap="200">
          <CommonTable
            resourceName={{
              singular: "Candidate",
              plural: "Candidates",
            }}
            title="Candidate list"
            queryPlaceholder={!sourcing ? "Search Candidate by (Email, Full Name, Mobile,Created By)" : ""}
            url={
              fatchsearchValue
                ? `/fatchCandidate/${id}`
                : sourcing && !loading
                ? "/getcandidatesData"
                : `/candidates/${id}`
            }
            isFilterVisible
            fetchDataNumber={fetchDataNumber}
            // selectable={true}
            rowsData={createRowsData}
            setParentPagination={setParentPagination}
            setParentSelectedTab={setParentSelectedTab}
            headings={[
              { title: "No" },
              { title: "Personal Details" },
              { title: "Work Details" },
              { title: "Analytics" },
              { title: "Assessment Details", width: "200px" },
              jobData?.isActive || sourcing ? { title: "Action" } : "",
            ]}
            ref={childRef}
            columnContentTypes={["string", "string", "string", "string", "string", "Action"]}
            searchKey={["email", "fullName", "mobile", "userdetail.firstName"]}
            pinnedFilter={
              !sourcing
                ? [
                    "reject",
                    "totalScore",
                    "assessmentStatus",
                    "isStar",
                    "noticePeriod",
                    "candidateRate",
                    "resumeScore",
                  ]
                : ""
            }
            isAdd={false}
            showTab={true}
            tabOption={stageTabs}
            filterFormFields={!sourcing ? filterFormFields : []}
            clearSelection={clearSelection}
            localStorageKey={`candidateList`}
            setCount={setResumeCount}
            promotedBulkActions={[
              {
                content: "Bulk Delete",
                onAction: handleDeleteBannerOpen,
              },
              {
                content: "Bulk Reject",
                onAction: handleRejectBannerOpen,
              },
              {
                content: "Bulk ShortList",
                onAction: handleShortListBannerOpen,
              },
              ...(sourcing
                ? []
                : [
                    {
                      content: "Bulk Send Assessment",
                      onAction: handleSendAssessmentBannerOpen,
                    },
                  ]),
            ]}
          />
        </BlockStack>
        <BannerModal
          size={"large"}
          open={isCandidateModelOpen}
          onClose={handleModalClose}
          title={actionType === "update" ? "Edit Candidate" : "Add New Candidate"}
          primaryAction={{
            content: "Submit",
            onAction: saveJob,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleModalClose,
            },
          ]}
          children={
            <CommonForm
              onSubmit={handleSubmit}
              formRef={formRef}
              initialValues={candidateFormValues}
              formFields={getCandidateDetailFields(jobData)}
              title="Add New Candidate"
              isSave={false}
            />
          }
        />
        <BannerModal
          open={isPremiumModelOpen}
          onClose={handlePremiumModelClose}
          title={"Upgrade to Premium"}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handlePremiumModelClose,
            },
          ]}
          children={<Plan config={pricingConfig} />}
          size={"large"}
        />
        <Modal
          open={isDeleteModalOpen}
          onClose={handleDeleteCanceled}
          title="Confirm Delete"
          primaryAction={{
            content: "Delete",
            onAction: handleDeleteConfirmed,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: handleDeleteCanceled,
            },
          ]}
        >
          <Modal.Section>
            <Text>Are you sure you want to delete this candidate?</Text>
          </Modal.Section>
        </Modal>
        <BannerModal
          open={isAssessmentModalOpen}
          onClose={closeAssessmentModal}
          title={"Send Assessment"}
          primaryAction={{
            content: "Send",
            onAction: handleSendAssessment,
            disabled: isSendingAssessment,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: closeAssessmentModal,
              disabled: isSendingAssessment,
            },
          ]}
          children={
            <BlockStack gap="200">
              <Text>Send To: {selectedCandidate?.fullName}</Text>
              <Text>Custom Question: {jobData?.customQuestion?.length}</Text>
              <Text>AI generated Question: {totalQuestions - jobData?.customQuestion?.length}</Text>
              <CommonForm
                formRef={customQuestionsformRef}
                initialValues={TotalQuestionsInitialValues}
                formFields={TotalQuestionsFields}
                title="Total Questions"
                isSave={false}
                onFormChange={handleRangeChange}
              />
            </BlockStack>
          }
        />
      </div>

      <Modal
        open={showConfirmation}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkDeleteClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkDelete,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkDeleteClose,
          },
        ]}
        size="small"
      >
        <Modal.Section>
          <Text>Are you sure you want to {bulkAction} Candidates?</Text>
        </Modal.Section>
      </Modal>
      <Modal
        open={sendAssessmentModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessment,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={bulkSendAssessmentInitialValues}
            formFields={bulkSendAssessmentFormFields(bulkoptions, NextRound, NextSendAssessment, isLastStage)}
            isSave={false}
            onFormChange={handleAssessment}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={assessmentSendModel}
        title={`${bulkAction} Candidates`}
        onClose={handleBulkSendAssessmentClose}
        primaryAction={{
          content: `${bulkAction}`,
          onAction: handleBulkSendAssessmentMassage,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleBulkSendAssessmentClose,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
      <Modal
        open={isSendAssessment}
        onClose={handleSendAssessmentCanceled}
        title="Send Assessment Method"
        primaryAction={{
          content: "Send",
          onAction: handleSenAssessmentConfirmed,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleSendAssessmentCanceled,
          },
        ]}
      >
        <Modal.Section>
          <CommonForm
            onSubmit={handleSubmit}
            formRef={formRef}
            initialValues={sendAssessmentInitialValues}
            formFields={sendAssessmentFields}
            isSave={false}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};
